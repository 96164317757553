const PercentageBar = ({ percentage }) => {
  let barColor;
  let gradientEnd;

  if (percentage < 25) {
    barColor = '#dc3545'; // red
    gradientEnd = '#f28e8e';
  } else if (percentage < 50) {
    barColor = '#ffc107'; // yellow
    gradientEnd = '#ffe58a';
  } else {
    barColor = '#28a745'; // green
    gradientEnd = '#8ed47f';
  }

  const barContainerStyle = {
    width: '150px',
    backgroundColor: '#e9ecef',
    borderRadius: '5px', // Slightly rounded corners for the container
    display: 'flex',
    marginLeft: '8px',
    marginBottom: '8px',
    alignItems: 'center',
    position: 'relative',
    height: '16px', // Slimmer bar for a modern look
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
  };

  const barStyle = {
    width: `${Math.min(100, percentage)}%`,
    background: `linear-gradient(to right, ${barColor}, ${gradientEnd})`, // Gradient effect
    height: '100%',
    borderRadius: '5px', // Rounded corners on both sides
    transition: 'width 0.3s ease-in-out', // Smooth transition for the loading effect
  };

  const textStyle = {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    color: '#4f4f4f',
    fontWeight: 'bold',
    fontSize: '12px', // Smaller font size for the text
    letterSpacing: '0.5px', // Spacing out the letters for better readability
  };

  return (
    <div style={barContainerStyle}>
      <div style={barStyle} />
      <span style={textStyle}>{percentage.toFixed(0)}% Match</span>
    </div>
  );
};

export default PercentageBar;
