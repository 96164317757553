import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { getAvailableFunnels } from '../../../../../api/CandidateAPI/CandidateAPI';
import { useMounted } from '../../../hooks';
import FunnelInputGroup from './FunnelInputGroup';

const selectProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
});

async function getFunnels(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getAvailableFunnels());

  if (typeof resp === 'string') {
    toast.error('There has been a problem loading the funnels');
  } else {
    onSuccess(resp);
  }
}

function FunnelSelectInputs({ funnelSelectProps, stageSelectProps, onChange }) {
  const isMounted = useMounted();
  const [funnels, setFunnels] = useState([]);
  const [stages, setStages] = useState({});
  const [selected, setSelected] = useState({ funnelId: '', stageId: '' });
  const [stageOptions, setStageOptions] = useState([]);

  useEffect(() => {
    getFunnels((resp) => {
      const funnelOpts = [];
      let stageOpts = {};

      resp.forEach(({ id, name, stages: stgArr }) => {
        funnelOpts.push({ value: id, label: name });
        stageOpts = {
          ...stageOpts,
          [id]: stgArr.map(({ stageId, name: stgName }) => ({ value: stageId, label: stgName })),
        };
      });

      if (isMounted()) {
        setFunnels(funnelOpts);
        setStages(stageOpts);

        if (funnelOpts.length === 1) {
          setSelected((prevState) => ({ ...prevState, funnelId: funnelOpts[0].value }));
          setStageOptions(stageOpts[funnelOpts[0].value]);
        }
      }
    });
  }, [isMounted]);

  useEffect(() => {
    const { value: fnlVal = '' } = funnelSelectProps;
    const { value: stgVal = '' } = stageSelectProps;

    if (funnels.length > 1 && fnlVal) {
      setSelected((prevState) => ({
        ...prevState,
        ...(fnlVal ? { funnelId: fnlVal } : {}),
        ...(stgVal ? { stageId: stgVal } : {}),
      }));
      setStageOptions(stages[fnlVal]);
    }
  }, [funnelSelectProps, funnels.length, stageSelectProps, stages]);

  function handleChange(id, val) {
    const updated = { ...selected, [id]: val };
    setSelected(updated);
    onChange(updated);
  }

  return (
    <div className="form-group">
      {funnels.length !== 1 && (
        <FunnelInputGroup
          {...funnelSelectProps}
          onChange={(val) => {
            let stageOpts = [];
            if (stages[val]) stageOpts = [...stages[val]];
            setStageOptions(stageOpts);
            handleChange('funnelId', val);
          }}
          options={funnels}
          placeholder="Choose Funnel"
        />
      )}
      {!!selected?.funnelId.length && !stageSelectProps.hidden && (
        <FunnelInputGroup
          {...stageSelectProps}
          onChange={(val) => handleChange('stageId', val)}
          options={stageOptions}
          placeholder="Choose Stage"
        />
      )}
    </div>
  );
}

FunnelSelectInputs.propTypes = {
  funnelSelectProps: selectProps,
  stageSelectProps: selectProps,
  onChange: PropTypes.func,
};

FunnelSelectInputs.defaultProps = {
  funnelSelectProps: {},
  stageSelectProps: {},
  onChange: () => {},
};

export default FunnelSelectInputs;
