export function getNextId(nodes) {
  if (nodes.length === 0) return '0';
  return String(Math.max(...nodes.map((node) => parseInt(node.id, 10))) + 1);
}

export function findClosestNode(position, nodes) {
  let closestNode = null;
  let closestDistance = Infinity; // Initialize with a large number

  nodes.forEach((node) => {
    const nodeBounds = {
      x: node.position.x,
      y: node.position.y,
      width: node.measured?.width,
      height: node.measured?.height,
    };

    // Calculate the center of the node
    const nodeCenter = {
      x: nodeBounds.x + nodeBounds.width / 2,
      y: nodeBounds.y + nodeBounds.height / 2,
    };

    // Calculate the Euclidean distance from the position to the node center
    const distance = Math.sqrt(Math.pow(position.x - nodeCenter.x, 2) + Math.pow(position.y - nodeCenter.y, 2));

    // Check if position is within the expanded bounds (100px from each side)
    const isWithinBounds =
      position.x >= nodeBounds.x - 100 &&
      position.x <= nodeBounds.x + nodeBounds.width + 100 &&
      position.y >= nodeBounds.y - 100 &&
      position.y <= nodeBounds.y + nodeBounds.height + 100;

    // If within bounds and the distance is less than the closestDistance, update closestNode
    if (isWithinBounds && distance < closestDistance) {
      closestNode = node;
      closestDistance = distance; // Update the closest distance
    }
  });

  return closestNode;
}

function getStartNodeFiltersToServer(nodeInfo) {
  let filters = {};

  switch (nodeInfo?.actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION':
      filters = [{ field: 'status', operation: 'EQ', value: [nodeInfo.condition] }];
      break;
    case 'RTW_STATUS_CHANGE': {
      let tempFiler = [{ field: 'status', operation: 'IN', value: [nodeInfo.condition] }];

      if (nodeInfo.condition === 'RECEIVED') {
        tempFiler.push({ field: `rtwValidity`, operation: 'IN', value: [nodeInfo.rtwValidity] });
      }

      filters = tempFiler;

      break;
    }
    case 'CREATE_CANDIDATE_EVENT':
      let tempFiler = [];

      if (
        nodeInfo.condition?.value === 'UTMMEDIUM' ||
        nodeInfo.condition?.value === 'UTMCAMPAIGN' ||
        nodeInfo.condition?.value === 'UTMSOURCE'
      ) {
        let paramName = 'utmMedium';
        if (nodeInfo.condition?.value === 'UTMCAMPAIGN') {
          paramName = 'utmCampaign';
        } else if (nodeInfo.condition?.value === 'UTMSOURCE') {
          paramName = 'utmSource';
        }

        tempFiler.push({ field: `utm.${paramName}`, operation: 'EQ', value: [nodeInfo.candidateFilterVal] });
      } else {
        tempFiler.push({ field: 'funnelId', operation: 'EQ', value: [nodeInfo.funnelId] });
      }
      filters = tempFiler;
      break;
    case 'EVENT_STATUS_CHANGE':
      filters = [
        { field: 'eventTypeId', operation: 'IN', value: nodeInfo.eventType },
        { field: 'status', operation: 'EQ', value: [nodeInfo.condition] },
      ];
      break;
    case 'FUNNEL_STATE_CHANGE':
      filters = [
        { field: 'funnelData.funnelId', operation: 'IN', value: [nodeInfo.funnelId] },
        { field: 'funnelData.stageId', operation: 'IN', value: [nodeInfo.stageId] },
      ];
      break;
    case 'PERSONALITY_TEST_STATUS_CHANGE':
      filters = [{ field: 'status', operation: 'EQ', value: [nodeInfo.condition] }];

      if (nodeInfo.condition === 'RECEIVED' && nodeInfo?.operator?.value !== 'ANY') {
        filters.push(
          { field: 'operator', operation: 'EQ', value: [nodeInfo.operator.value] },
          { field: 'score', operation: 'EQ', value: [nodeInfo.scoreValue] },
          { field: 'scoreType', operation: 'EQ', value: [nodeInfo.scoreType.value] },
        );
      }
      break;
    default:
      filters = {};
      break;
  }

  return filters;
}

function convertTime(number, range) {
  let timeInMinutes = 0;

  if (range === 'min') {
    timeInMinutes = number * 60;
  } else if (range === 'hour') {
    timeInMinutes = number * 60 * 60;
  } else if (range === 'day') {
    timeInMinutes = number * 60 * 60 * 24;
  }

  return timeInMinutes;
}

export function convertWorkflowToServer(workflow) {
  const { nodes, edges, name, activeAccountId, sharedWith, exitConditions, status } = workflow;

  const startNode = nodes.find((node) => node.type === 'startNode');

  const convertedWorkflow = {
    name: name,
    accountId: activeAccountId,
    type: startNode?.data?.actionType,
    sharedWith: sharedWith,
    stages: [],
    dimensions: {
      w: startNode.measured.width,
      h: startNode.measured.height,
    },
    coords: startNode.position,
    filters: getStartNodeFiltersToServer(startNode.data),
    status: status,
  };

  if (exitConditions && exitConditions.length > 0) {
    convertedWorkflow.exitConditions = [
      {
        condition: { field: 'latestFunnelStage.stageType', operation: 'EQ', value: exitConditions },
        evaluatorType: 'CANDIDATE',
      },
    ];
  }

  const filteredNodes = nodes.filter((node) => node.type !== 'startNode');

  const BRANCHMAP = {};

  filteredNodes.forEach((node) => {
    const { label, actionType, id, ...rest } = node.data;

    let stage = {
      name: label,
      label: label,
      stageNo: parseInt(node.id),
      type: actionType,
      delay: 0,
      props: {
        ...rest,
      },
      actionOnFail: 'END',
      dimensions: {
        w: node.measured.width,
        h: node.measured.height,
      },
      coords: node.position,
    };

    if (actionType === 'WAIT') {
      stage = {
        ...stage,
        props: {
          ...stage.props,
          delay: convertTime(stage.props.number, stage.props.range),
        },
      };
    }

    if (actionType === 'BRANCH') {
      BRANCHMAP[node.id] = stage;
    }

    if (actionType === 'EXIT') {
      stage = {
        ...stage,
        actionOnFail: 'END',
      };
    }
    convertedWorkflow.stages.push(stage);
  });

  let updatedEdges = [];

  edges.forEach((edge) => {
    let newEdge = edge;

    if (BRANCHMAP[edge.source]) {
      if (edge.type === 'noEdge') {
        newEdge = {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          type: 'DefaultEdge',
          conditionLogic: 'AND',
          evaluatorType: 'CANDIDATE',
          elseConnection: true,
        };
      } else {
        const branch = BRANCHMAP[edge.source];

        newEdge = {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          type: 'DefaultEdge',
          conditionLogic: branch.props.conditionLogic,
          evaluatorType: 'CANDIDATE',
          elseConnection: false,
          conditions: branch.props.conditions?.map((condition) => {
            const field =
              condition.value === 'funnelStatus' ? 'latestFunnelStage.stageType' : 'combinedQuestionAnswerStrings';

            if (field === 'combinedQuestionAnswerStrings') {
              return {
                field: `${field}.${condition?.condition?.question}`,
                value: condition?.condition?.answer[0],
                operation: 'EQ',
              };
            } else {
              return {
                field: field,
                operation: 'EQ',
                value: [condition?.condition?.condition],
              };
            }
          }),
        };
      }
    }

    updatedEdges.push(newEdge);
  });

  convertedWorkflow.edges = updatedEdges;

  return convertedWorkflow;
}

function getStartNodeFiltersFromServer(filters, actionType) {
  let nodeInfo = {};

  switch (actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION': {
      const statusFilter = filters.find((filter) => filter.field === 'status');
      nodeInfo.condition = statusFilter ? statusFilter.value[0] : '';
      break;
    }

    case 'CREATE_CANDIDATE_EVENT': {
      const utmFilter = filters.find((filter) => filter.field.startsWith('utm.'));
      const funnelFilter = filters.find((filter) => filter.field === 'funnelId');

      if (utmFilter) {
        const utmField = utmFilter.field.split('.')[1];
        nodeInfo.condition = { value: utmField.toUpperCase() };
        nodeInfo.candidateFilterVal = utmFilter.value[0];
      } else if (funnelFilter) {
        nodeInfo.funnelId = funnelFilter.value[0];
        nodeInfo.condition = { value: 'ALL' };
      }
      break;
    }

    case 'EVENT_STATUS_CHANGE': {
      const eventTypeFilter = filters.find((filter) => filter.field === 'eventTypeId');
      const statusFilter = filters.find((filter) => filter.field === 'status');

      nodeInfo.eventType = eventTypeFilter ? eventTypeFilter.value[0] : '';
      nodeInfo.condition = statusFilter ? statusFilter.value[0] : '';
      break;
    }

    case 'FUNNEL_STATE_CHANGE': {
      const funnelIdFilter = filters.find((filter) => filter.field === 'funnelData.funnelId');
      const stageIdFilter = filters.find((filter) => filter.field === 'funnelData.stageId');

      nodeInfo.funnelId = funnelIdFilter ? funnelIdFilter.value[0] : '';
      nodeInfo.stageId = stageIdFilter ? stageIdFilter.value[0] : '';
      break;
    }

    case 'PERSONALITY_TEST_STATUS_CHANGE': {
      const statusFilter = filters.find((filter) => filter.field === 'status');
      nodeInfo.condition = statusFilter ? statusFilter.value[0] : '';

      if (nodeInfo.condition === 'RECEIVED') {
        const operatorFilter = filters.find((filter) => filter.field === 'operator');
        nodeInfo.operator = { value: operatorFilter ? operatorFilter.value[0] : '' };

        if (nodeInfo.operator.value !== 'ALL') {
          const scoreFilter = filters.find((filter) => filter.field === 'score');
          const scoreTypeFilter = filters.find((filter) => filter.field === 'scoreType');

          nodeInfo.scoreValue = scoreFilter ? scoreFilter.value[0] : '';
          nodeInfo.scoreType = { value: scoreTypeFilter ? scoreTypeFilter.value[0] : '' };
        }
      }
      break;
    }

    case 'RTW_STATUS_CHANGE': {
      const statusFilter = filters.find((filter) => filter.field === 'status');

      nodeInfo.condition = statusFilter ? statusFilter.value[0] : '';

      if (nodeInfo.condition === 'RECEIVED') {
        const rtwValidity = filters.find((filter) => filter.field === 'rtwValidity');
        nodeInfo.rtwValidity = { value: rtwValidity ? rtwValidity.value[0] : '' };
      }

      break;
    }

    default:
      break;
  }

  return nodeInfo;
}

export function convertWorkflowFromServer(convertedWorkflow) {
  const { name, accountId, type, sharedWith, stages, dimensions, coords, edges, filters, id, exitConditions, status } =
    convertedWorkflow;

  const nodes = [];
  const BRANCHMAP = {};

  // Reconstruct the start node
  const startNodeData = {
    actionType: type,
    ...getStartNodeFiltersFromServer(filters, type),
  };

  const startNode = {
    type: 'startNode',
    data: startNodeData,
    measured: {
      width: dimensions?.w || 50,
      height: dimensions?.h || 50,
    },
    position: coords,
    id: '0',
    selected: false,
  };

  nodes.push(startNode);

  // Reconstruct other nodes
  stages.forEach((stage) => {
    const { label, type: actionType, props, dimensions: stageDimensions, coords: stageCoords, stageNo } = stage;

    const nodeData = {
      label,
      actionType,
      ...props,
    };

    let nodeType = 'actionNode';

    if (actionType === 'WAIT') {
      nodeData.number = props.number;
      nodeData.range = props.range;
      nodeType = 'waitNode';
    }

    if (actionType === 'EXIT') {
      nodeType = 'endNode';
    }

    if (actionType === 'BRANCH') {
      nodeType = 'decisionNode';
      BRANCHMAP[stageNo] = nodeData; // Store branch node info in BRANCHMAP for edge processing
    }

    const node = {
      data: nodeData,
      type: nodeType,
      id: stageNo.toString(),
      measured: {
        width: stageDimensions?.w || 50,
        height: stageDimensions?.h || 50,
      },
      position: stageCoords,
      selected: false,
    };

    nodes.push(node);
  });

  let funnelStageExitConditions = [];

  if (exitConditions) {
    exitConditions?.forEach((condition) => {
      if (condition?.field === 'latestFunnelStage.stageType' && condition.value.length > 0) {
        debugger
        if (condition?.operation === 'IN') {
          funnelStageExitConditions = condition?.value;
        } else if (condition?.operation === 'EQ') {
          funnelStageExitConditions = [condition?.value];
        }
      }
    });
  }

  // Process edges and move branch conditions to nodes
  edges.forEach((edge) => {
    if (BRANCHMAP[edge.source]) {
      const branchNodeData = BRANCHMAP[edge.source];

      if (!branchNodeData.conditions) {
        branchNodeData.conditions = [];
      }

      if (edge.elseConnection) {
        branchNodeData.elseConnection = edge.elseConnection;
      } else {
        // Map the conditions from the edge to the branch node
        branchNodeData.conditions.push({
          field: edge.conditions?.[0]?.field,
          operation: edge.conditions?.[0]?.operation,
          value: edge.conditions?.[0]?.value,
        });
      }
    }
  });

  const updatedEdges = edges.map((edge) => {
    let updatedEdge = {
      ...edge,
      source: edge.source.toString(),
      target: edge.target.toString(),
    };

    if (typeof edge.elseConnection === 'boolean') {
      if (!edge.elseConnection) {
        updatedEdge = {
          ...updatedEdge,
          label: 'Yes',
          sourceHandle: 'yes',
          type: 'yesEdge',
        };
      } else {
        updatedEdge = {
          ...updatedEdge,
          label: 'No',
          sourceHandle: 'no',
          type: 'noEdge',
        };
      }
    }
    return updatedEdge;
  });

  const workflow = {
    nodes,
    edges: updatedEdges,
    name,
    activeAccountId: accountId,
    sharedWith,
    id,
    exitConditions: funnelStageExitConditions,
    status,
  };

  return workflow;
}
