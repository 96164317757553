import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import FunnelSelectInputs from '../../../../../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import Select from 'react-select';
import {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
} from '../../../../EventOptions';

import ApplicationQuestions from './ApplicationQuestions';

const funnelStatusStates = [
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Hired', value: 'HIRED' },
];

function Conditional({ option, setOption }) {
  if (option?.value === 'funnelStage') {
    return (
      <>
        <FormGroup>
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: option.funnelId || '',
            }}
            onChange={(val) => setOption({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: option.stageId || '',
            }}
          />
        </FormGroup>
      </>
    );
  }

  if (option?.value === 'applicationQuestion') {
    return <ApplicationQuestions option={option} setOption={setOption} />;
  }

  if (option?.value === 'funnelStatus') {
    return (
      <>
        <FormGroup>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={funnelStatusStates}
            value={funnelStatusStates.find((a) => a.value === option?.condition?.condition)}
            onChange={(val) => setOption({ condition: val.value })}
          />
        </FormGroup>
      </>
    );
  }

  if (option?.value === 'payrollSubmission') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={payrollStates}
          value={payrollStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (
    option?.value === 'onboardingStatus' ||
    option?.value === 'edocStatus' ||
    option?.value === 'dbsStatus' ||
    option?.value === 'refereeStatus' ||
    option?.value === 'flexFormStatus'
  ) {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={defaultStates}
          value={defaultStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (option?.value === 'referenceStatus') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={referenceStates}
          value={referenceStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (option?.value === 'rtwStatus') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={rtwStates}
            value={rtwStates.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption({ condition: val.value })}
          />
        </FormGroup>
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Outcome<span className="required">*</span>
            </Label>
            <Select
              className="react-select"
              placeholder="Select Outcome"
              options={rtwOutcomes}
              value={rtwOutcomes.find((a) => a.value === option?.outcome)}
              onChange={(val) => setOption({ outcome: val.value })}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (option?.value === 'personalityTestStatus') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={personalityTestState}
            value={personalityTestState.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption({ condition: val.value })}
          />
        </FormGroup>
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              When Score is<span className="required">*</span>
            </Label>
            <Select
              className="react-select"
              placeholder="Select Outcome"
              options={personalityTestScoreValues}
              value={personalityTestScoreValues.find((a) => a.value === option?.outcome)}
              onChange={(val) => setOption({ outcome: val.value })}
            />
          </FormGroup>
        )}
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Score Value<span className="required">*</span>
            </Label>
            <Input
              onChange={(e) => {
                setOption({ scoreValue: e.target.value });
              }}
              type="text"
              value={scoreValue}
            />
          </FormGroup>
        )}
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Score Type<span className="required">*</span>
            </Label>
            <Select
              className="react-select"
              placeholder="Select Score type"
              options={personalityTestScoreTypes}
              value={personalityTestScoreTypes.find((a) => a.value === option?.scoreType)}
              onChange={(val) => setOption({ scoreType: val.value })}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (option?.value === 'eventStatus') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select"
          placeholder="Select Funnel Stage"
          options={eventStates}
          value={statusValues.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (option?.value === 'personalityTestStatus') {
    return (
      <>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Funnel Stage"
            options={payrollStates}
            value={payrollStates.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption({ condition: val.value })}
          />
        </FormGroup>
      </>
    );
  }

  if (option?.value === 'newCandidate') {
    return (
      <>
        <FormGroup required>
          <Label>
            Candidate Filter Type<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Funnel Stage"
            options={newCandidateFilterTypes}
            value={newCandidateFilterTypes.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption({ condition: val.value })}
          />
        </FormGroup>
        {(option?.condition === 'utmSource' ||
          option?.condition === 'utmCampaign' ||
          option?.condition === 'utmMedium') && (
          <FormGroup required>
            <Label>
              Value<span className="required">*</span>
            </Label>
            <Input
              onChange={(e) => setOption({ scoreValue: e.target.value })}
              type="text"
              placeholder="SEt value to filter this workflow on"
              value={option?.scoreValue}
            />
          </FormGroup>
        )}
        {option?.condition === 'all' && (
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: option.funnelId || '',
            }}
            onChange={(val) => setOption({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: option.stageId || '',
              hidden: true,
            }}
          />
        )}
      </>
    );
  }

  return <div>No Condition</div>;
}

Conditional.propTypes = {
  option: PropTypes.shape(),
};

Conditional.defaultProps = {
  isSliderOpen: {},
};

export default Conditional;
