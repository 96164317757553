import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToNory } from '../../../../api/Integrations/NoryAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Integration from './Integration';

const NoryInputConfig = [
  {
    id: 'location',
    label: 'Location',
    type: 'select',
    required: true,
    url: 'integration/data/nory/branches',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'division',
    label: 'Division',
    type: 'select',
    required: true,
    url: 'integration/data/nory/roles',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    type: 'select',
    required: true,
    url: 'integration/data/nory/positions',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'paidByRota',
    label: 'Paid By Rota',
    type: 'select',
    required: true,
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    id: 'payType',
    label: 'Pay Type',
    type: 'select',
    required: true,
    url: 'integration/data/nory/wageType',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'includedInRota',
    label: 'Included in Rota',
    type: 'select',
    required: true,
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    id: 'rateOfPay',
    label: 'Rate Of Pay',
    type: 'number',
    required: true,
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'annualSalary',
    label: 'Annual Salary',
    required: true,
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'employmentType',
    label: 'Employment Type',
    type: 'select',
    required: true,
    options: [
      { label: 'Full Time', value: 'full time' },
      { label: 'Part Time', value: 'part time' },
      { label: 'Contractor', value: 'contractor' },
    ],
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'employeeSource',
    label: 'Employee Source',
    type: 'select',
    required: true,
    options: [
      { label: 'Agency', value: 'Agency' },
      { label: 'ATS', value: 'ATS' },
      { label: 'Caterer', value: 'Caterer' },
      { label: 'Own Website', value: 'Own Website' },
      { label: 'Indeed', value: 'Indeed' },
      { label: 'Recommendation', value: 'Recommendation' },
      { label: 'Unknown', value: 'Unknown' },
      { label: 'LinkedIn', value: 'LinkedIn' },
    ],
  },
  {
    id: 'startDate',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'terminationDate',
    label: 'Termination Date',
    type: 'date',
  },
];

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  if (isComplete) {
    convertedValue = formData[id];
  }

  return { value: convertedValue, url, isDisabled, isHidden: false };
}

function NoryForm({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {

      const dataObj = {dataFields: {
        candidateId: applicantId,
          location: formData.location,
          division: formData.division,
          jobTitle: formData.jobTitle,
          paidByRota: formData.paidByRota,
          payType: formData.payType,
          includedInRota: formData.includedInRota,
          rateOfPay: formData.rateOfPay,
          annualSalary: formData.annualSalary,
          employmentType: formData.employmentType,
          contractHours: formData.contractHours,
          employeeSource: formData.employeeSource,
          startDate: formData.startDate,
          terminationDate: formData.terminationDate,
      }}

      const result = await submitToNory(applicantId, dataObj);

      debugger
      const {status: status, error } = result
      if(status >= 400 && status <= 503) {
        return { status: 'ERROR', message: error };
      } else {
        return { data: dataObj.dataFields}
      }
    } catch (error) {
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    const updatedData = { ...formData, [id]: value };

    setFormData(updatedData);
  };

  async function getNoryData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === '') {
        return 'NOT_FOUND_ERROR';
      }

      const { status, message, submissionRequest } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status === 'PENDING' ? 'FAILED' : status,
          message: message,
          data: resp?.supplementaryDataFields?.dataFields,
        };
      }

      return {
        status: 'SUCCESS',
        data: resp?.supplementaryDataFields?.dataFields,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return NoryInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, type, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };

  return (
    <Integration
      title="Nory"
      applicantId={applicantId}
      valueSetter={valueSetter}
      integrationName="Nory"
      formConfig={NoryInputConfig}
      formSubmit={formSubmit}
      getSubmission={getNoryData}
      componentName="Nory"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

NoryForm.propTypes = {
  applicantId: PropTypes.string,
};

NoryForm.defaultProps = {
  applicantId: null,
};

export default NoryForm;
