import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { SliderContext } from './SliderContext';
import { useHover } from '../../HoverContext';
import styled from 'styled-components';
import { decisionNodes } from '../NodeTypesLists';
import ActionButtons from './ActionButtons';

const StyledCont = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
`;

const Icon = styled.div`
  background-color: orange;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }
`;

export default memo(({ id, data, isConnectable }) => {
  const nodeType = decisionNodes.find((node) => node.actionType === data?.actionType);
  const IconComp = nodeType?.icon;
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <StyledCont onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} />
        <Handle type="target" position={Position.Top} />
        <Container>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">Decision</div>
            <div className="status">{data.condition}</div>
          </Text>
        </Container>
        <Handle
          type="source"
          position={Position.Bottom}
          id="yes"
          style={{ left: '25%', background: '#555' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="no"
          style={{ right: '25%', left: 'unset', background: '#555' }}
          isConnectable={isConnectable}
        />
      </StyledCont>
    </SliderContext.Provider>
  );
});
